<template>
  <div>

    <b-button
      class="mb-3"
      variant="outline-primary"
      @click="showModal('新增')"
    >新增</b-button>
    
    <b-table
      :items="resources.thermalCameraPixel"
      :fields="fields"
      label-sort-asc=""
      label-sort-desc=""
      label-sort-clear=""
      striped
      hover
      small
      class="table-light"
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(functions)="data">
        <span class="d-flex flex-row flex-wrap" style="gap: .25rem;">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="showModal('編輯', data.item)"
            >編輯</b-button
          >
          <b-button
            variant="outline-danger"
            size="sm"
            @click="deleteThermalCameraPixel(data.item)"
          >刪除</b-button>
        </span>
      </template>
    </b-table>

    <b-modal
      size="lg"
      ref="modalRef"
      :title="modalTitle"
      ok-title="儲存"
      cancel-title="取消"
      :no-close-on-backdrop="true"
      :no-close-on-esc="false"      
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-row>
          <b-col cols="4">
            <b-form-group
              label="x"
              invalid-feedback="必填"
              :state="state.x"
              class="fm-required"
            >
              <b-form-input
                v-model="form.x"
                type="number"
                autocomplete="off"
                @input="checkState('x')"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="y"
              invalid-feedback="必填"
              :state="state.y"
              class="fm-required"
            >
              <b-form-input
                v-model="form.y"
                type="number"
                autocomplete="off"
                @input="checkState('y')"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      modalTitle: null,
      thermalCameraPixelID: null,
      state: {
        x: null,
        y: null,
      },
      form: {
        x: null,
        y: null,
      },
      resources: {
        thermalCameraPixel: [],
      },
      fields: [
        { key: 'index', label: '#' },
        { key: 'id', label: 'ID' },
        { key: 'x', label: 'x', sortable: true },
        { key: 'y', label: 'y', sortable: true },
        { key: 'created_at', label: '產生時間', sortable: true },
        { key: 'updated_at', label: '更新時間', sortable: true },
        { key: 'functions', label: '功能' },
      ],
    }
  },
  methods: {
    deleteCameraPixel(item) {
      if (confirm("確定刪除！")) {
        this.axios
          .delete(`/thermal-camera-pixel/${item.id}`)
          .then(() => {
            const index = this.resources.thermalCameraPixel.findIndex(
              (element) => element.id == item.id
            );
            this.resources.thermalCameraPixel.splice(index, 1);

            this.makeToast("系統訊息", "success", "刪除成功！");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    checkState (name) {
      // Convert value to a number
      const value = parseFloat(this.form[name]);
      // Check if value is numeric and greater than zero
      if (!isNaN(value) && value > 0) {
        this.state[name] = true; // valid
      } else {
        this.state[name] = false; // invalid
      }
    },
    showModal (type, row) {
      this.$refs.modalRef.show();
      if (type == '新增') {
        this.modalTitle = type;
      // 編輯
      } else {
        this.modalTitle = `${type} - ID ${row.id}`;
        this.thermalCameraPixelID = row.id;

        for (let prop in this.form) {
          this.form[prop] = row[prop];
        }
      }
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity();

      for (let prop in this.state) {
        if (prop === 'x' || prop === 'y') {
          // Convert value to a number and check if greater than zero
          const value = parseFloat(this.form[prop]);
          this.state[prop] = !isNaN(value) && value > 0;
        } else {
          // Default validation for other fields
          this.state[prop] = this.form[prop] ? true : false;
        }
      }
      
      return valid;
    },
    resetModal () {
      /**
       * 回復預設值
       */
      for (let prop in this.form) {
        this.form[prop] = null
      }

      for (let prop in this.state) {
        this.state[prop] = null
      }
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      if (this.modalTitle == '新增') {
        this.axios
          .post('/thermal-camera-pixel', this.form)
          .then(response => {
            this.resources.thermalCameraPixel.push(response.data.data[0]);

            this.makeToast('系統訊息', 'success', '新增成功！');
          })
          .catch(error => {
            console.log(error);
          });
      // 編輯
      } else {
        let request = Object.assign({}, this.form);

        this.axios.patch('/thermal-camera-pixel/' + this.thermalCameraPixelID, request)
          .then(response => {
            let index = this.resources.thermalCameraPixel.findIndex(element => element.id == response.data.data[0].id);
            
            this.resources.thermalCameraPixel.splice(index, 1, response.data.data[0]);
            this.makeToast('系統訊息', 'success', '資料已更新！');
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // Hide the modal manually
      this.$nextTick (() => {
        this.$refs.modalRef.hide()
      });
    },
  },
  created () {
    this.axios
      .get('/thermal-camera-pixel')
      .then(response => {
        this.resources.thermalCameraPixel = response.data.data;
      })
      .catch(error => {
        console.log(error);
      })
  }
}
</script>

<style scoped>
.table >>> thead th {
  position: sticky !important;
  top: 0;
  text-align: center;
  z-index: 2;
  background-color: white;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.3);
  border-bottom: 0px;
}
.table >>> thead th,
.table >>> tbody td {
  font-size: 0.9rem;
  vertical-align: middle !important;
  /* 防止資料折行 */
  word-break: keep-all;
  /*white-space:nowrap;*/
}

/*
 * 必填欄位加 *
 */
 .fm-required >>> legend::after {
  color: red;
  content: " *";
}
</style>
