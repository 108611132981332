<template>
  <div>
    <b-form-row>
      <b-col cols="3">
        <b-form-group
          label="公司"
        >
          <b-form-select
            v-model="form.company_id"
            :options="resources.companys"
            value-field="id"
            text-field="name"
            @change="changeCompany"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
          label="案場"
        >
          <b-form-select
            v-model="form.case_scene_id"
            :options="resources.caseScenes"
            value-field="id"
            text-field="name"
            @change="changeDetection"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
          label="檢測 ID"
        >
          <b-form-select
            v-model="form.detection_id"
            :options="resources.detections"
            value-field="id"
            text-field="id"
            @change="changeThermalDefect"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-row>
          <b-col cols="8">
            <b-form-group
              label="上傳檔案"
              description="僅支援 CSV、JPG、PNG、BMP 檔"
            >
              <b-form-file
                v-model="form.files"
                multiple
                ref="ref-files"
                browse-text="瀏覽"
                placeholder="可多選"
                drop-placeholder="將檔案放到這裡"
              ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-button
              variant="outline-primary"
              @click="addFiles"
              :disabled="!form.files.length"
              style="margin-top: 31px;"
            >上傳</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>

    <b-table
      :items="resources.thermalDefects"
      :fields="fields"
      striped
      hover
      small
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(combine_photo)="data">
        <img
          v-if="data.item.combine_photo"
          :src="`https://${rootUrl}/combine-photos/${data.item.combine_photo}`"
          height="80"
        >
      </template>
      <template #cell(created_at)="data">
        {{ new Date(data.item.created_at).format('Y-m-d H:i') }}
      </template>
      <template #cell(updated_at)="data">
        {{ data.item.updated_at ? new Date(data.item.updated_at).format('Y-m-d H:i') : '' }}
      </template>
      <!-- <template #cell(functions)="data">
        <b-button
          variant="outline-primary"
          size="sm"
          class="mr-2"
          @click="showModal('編輯', data.item)"
          >編輯</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          @click="deleteDetection(data.item)"
          >刪除</b-button
        >
      </template> -->
    </b-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      rootUrl: 'platform.iruav.com.tw/uploads',
      form: {
        company_id: null,
        case_scene_id: null,
        detection_id: null,
        files: []
      },
      resources: {
        companys: [],
        caseScenes: [],
        detections: [],
        thermalDefects: []
      },
      fields: [
        { key: 'index', label: '#' },
        { key: 'id', label: 'ID' },
        { key: 'longitude', label: '經度', sortable: true },
        { key: 'latitude', label: '緯度', sortable: true },
        { key: 'grid_x', label: '網格座標 X (%)', sortable: true },
        { key: 'grid_y', label: '網格座標 Y (%)', sortable: true },
        { key: 'detection_path', label: '檢測路徑', sortable: true },
        { key: 'photo_number', label: '照片編號', sortable: true },
        { key: 'combine_photo', label: '雙光合成圖', sortable: true },
        { key: 'note', label: '備註', sortable: true },
        { key: 'thermal_defect_category_name', label: '熱缺陷種類', sortable: true },
        { key: 'created_at', label: '產生時間', sortable: true },
        { key: 'updated_at', label: '更新時間', sortable: true }
      ],
    }
  },
  methods: {
    addFiles () {
      let formData = new FormData();

      formData.append('detection_id', this.form.detection_id);

      for (let i = 0, len = this.form.files.length; i < len; i++) {
        formData.append('files[]', this.form.files[i]);
      }

      this.axios.post('/thermal-defect', formData)
        .then(() => {
          this.$refs['ref-files'].reset();
          this.getThermalDefect();

          this.makeToast('系統訊息', 'success', '上傳完成！');
        })
        .catch((error) => {
          console.log(error)
        });
    },
    getCaseScene () {
      this.axios
        .get('/case-scene', { params: {
          filter: `case_scene.company_id:${this.form.company_id}`
        }})
        .then(response => {
          this.resources.caseScenes = response.data.data;
          this.form.case_scene_id = this.resources.caseScenes[0].id;

          this.getDetection();
        })
        .catch(error => {
          console.log(error);
        });
    },
    changeCompany () {
      this.getCaseScene();
    },
    getDetection () {
      this.axios
        .get('/detection', { params: {
          filter: `detection.case_scene_id:${this.form.case_scene_id}`
        }})
        .then(response => {
          this.resources.detections = response.data.data;
          this.form.detection_id = this.resources.detections[0].id;

          this.getThermalDefect();
        })
        .catch(error => {
          console.log(error);
        });
    },
    changeDetection () {
      this.getDetection();
    },
    getThermalDefect () {
      this.axios
        .get('/thermal-defect', { params: {
          filter: `thermal_defect.detection_id:${this.form.detection_id}`
        }})
        .then(response => {
          this.resources.thermalDefects = response.data.data;
          this.form.thermal_defect_id = this.resources.thermalDefects[0].id;
        })
        .catch(error => {
          console.log(error);
        });
    },
    changeThermalDefect () {
      this.getThermalDefect();
    }    
  },
  created () {
    this.axios
      .get("/company")
      .then((response) => {
        this.resources.companys = response.data.data;
        this.form.company_id = this.resources.companys[0].id;

        this.getCaseScene();
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
</script>

<style scoped>
.table >>> thead th {
  position: sticky !important;
  top: 0;
  text-align: center;
  z-index: 2;
  background-color: white;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.3);
  border-bottom: 0px;
}
.table >>> thead th,
.table >>> tbody td {
  font-size: 0.9rem;
  vertical-align: middle !important;
  /* 防止資料折行 */
  word-break: keep-all;
  /*white-space:nowrap;*/
}

/*
 * 必填欄位加 *
 */
 .fm-required >>> legend::after {
  color: red;
  content: " *";
}
</style>
