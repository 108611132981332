<template>
  <div>
    <b-form-row>
      <b-col cols="3">
        <b-form-group
          label="公司"
        >
          <b-form-select
            v-model="form.company_id"
            :options="resources.companys"
            value-field="id"
            text-field="name"
            @change="changeCompany"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
          label="案場"
        >
          <b-form-select
            v-model="form.case_scene_id"
            :options="resources.caseScenes"
            value-field="id"
            text-field="name"
            @change="changeDetection"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-button
          variant="outline-primary"
          @click="showModal('新增')"
          style="margin-top: 31px;"
        >新增</b-button>
      </b-col>
    </b-form-row>

    <b-table
      :items="resources.detections"
      :fields="fields"
      striped
      hover
      small
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(show_details)="row">
        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails"></b-form-checkbox>
      </template>

      <template #row-details="row">
        <b-card>
          <b-form-row>
            <b-col cols="6">
              <b-form-group label="補充建議">
                <b-form-textarea
                  v-model="row.item.suggestion"
                  rows="3"
                  disabled
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="備註">
                <b-form-input
                  v-model="row.item.note"
                  type="text"
                  disabled
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-card>
      </template>

      <template #cell(video)="data">
        <video
          v-if="data.item.video"
          :src="`https://${rootUrl}/videos/${data.item.video}`"
          width="200"
          controls
        ></video>
      </template>
      <template #cell(case_scene_photo)="data">
        <img
          v-if="data.item.case_scene_photo"
          :src="`https://${rootUrl}/case-scene-photos/${data.item.case_scene_photo}`"
          height="80"
        >
      </template>
      <template #cell(orthophoto)="data">
        <img
          v-if="data.item.orthophoto"
          :src="`https://${rootUrl}/orthophotos/${data.item.orthophoto}`"
          height="80"
        >
      </template>
      <template #cell(thermal_camera_pixel)="data">
        {{ data.item.thermal_camera_pixel_x }} x {{ data.item.thermal_camera_pixel_y }}
      </template>
      <template #cell(created_at)="data">
        {{ new Date(data.item.created_at).format('Y-m-d H:i') }}
      </template>
      <template #cell(updated_at)="data">
        {{ data.item.updated_at ? new Date(data.item.updated_at).format('Y-m-d H:i') : '' }}
      </template>
      <template #cell(functions)="data">
        <b-button
          variant="outline-primary"
          size="sm"
          class="mr-2"
          @click="showModal('編輯', data.item)"
          >編輯</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          @click="deleteDetection(data.item)"
          >刪除</b-button
        >
      </template>
    </b-table>

    <b-modal
      size="lg"
      ref="modalRef"
      :title="modalTitle"
      ok-title="儲存"
      cancel-title="取消"
      :no-close-on-backdrop="true"
      :no-close-on-esc="false"      
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-row>
          <b-col v-if="modalTitle == '編輯'" cols="4">
            <b-form-group label="ID">
              <b-form-input
                v-model="form.report_number"
                type="text"
                plaintext
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="公司"
            >
              <b-form-select
                v-model="form.company_id"
                :options="resources.companys"
                value-field="id"
                text-field="name"
                disabled
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="案場"
            >
              <b-form-select
                v-model="form.case_scene_id"
                :options="resources.caseScenes"
                value-field="id"
                text-field="name"
                disabled
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="檢測日期"
              invalid-feedback="必選"
              :state="state.detection_date"
              class="fm-required"
            >
              <datepicker
                v-model="form.detection_date"
                :value="form.detection_date"
                :language="zh"
                :bootstrap-styling="true"
                format="yyyy-MM-dd"
                required
                @input="checkState('detection_date')"
              ></datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="報告日期"
              invalid-feedback="必選"
              :state="state.report_date"
              class="fm-required"
            >
              <datepicker
                v-model="form.report_date"
                :value="form.report_date"
                :language="zh"
                :bootstrap-styling="true"
                format="yyyy-MM-dd"
                required
                @input="checkState('report_date')"
              ></datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="日照"
              description="W/m2"
              :state="state.sunshine"
              invalid-feedback="必填"
              class="fm-required"
            >
              <b-form-input
                v-model="form.sunshine"
                type="number"
                step="1"
                autocomplete="off"
                required
                @input="checkState('sunshine')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="氣溫"
              description="度"
              :state="state.temperature"
              invalid-feedback="必填"
              class="fm-required"
            >
              <b-form-input
                v-model="form.temperature"
                type="number"
                step="1"
                autocomplete="off"
                required
                @input="checkState('temperature')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="風速"
              description="m/sec"
              :state="state.wind_speed"
              invalid-feedback="必填"
              class="fm-required"
            >
              <b-form-input
                v-model="form.wind_speed"
                type="number"
                step="0.1"
                autocomplete="off"
                required
                @input="checkState('wind_speed')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="雲遮蔽"
              description="%"
              :state="state.cloud_obscuration"
              invalid-feedback="必填"
              class="fm-required"
            >
              <b-form-input
                v-model="form.cloud_obscuration"
                type="number"
                step="1"
                max="100"
                autocomplete="off"
                required
                @input="checkState('cloud_obscuration')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="正射圖產生"
              invalid-feedback="必選"
              :state="state.orthophoto_date"
              class="fm-required"
            >
              <datepicker
                v-model="form.orthophoto_date"
                :value="form.orthophoto_date"
                :language="zh"
                :bootstrap-styling="true"
                format="yyyy-MM-dd"
                required
                @input="checkState('orthophoto_date')"
              ></datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="操作者"
              invalid-feedback="必選"
              :state="state.users_id"
              class="fm-required"
            >
              <b-form-select
                v-model="form.users_id"
                :options="resources.usersOperators"
                value-field="id"
                text-field="name"
                required
                @input="checkState('users_id')"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="天氣"
              invalid-feedback="必選"
              :state="state.weather_id"
              class="fm-required"
            >
              <b-form-select
                v-model="form.weather_id"
                :options="resources.weathersAll"
                value-field="id"
                text-field="name"
                required
                @input="checkState('weather_id')"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="清潔狀況"
              invalid-feedback="必選"
              :state="state.clean_id"
              class="fm-required"
            >
              <b-form-select
                v-model="form.clean_id"
                :options="resources.cleansAll"
                value-field="id"
                text-field="name"
                required
                @input="checkState('clean_id')"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="熱像儀像素"
              invalid-feedback="必選"
              :state="state.thermal_camera_pixel_id"
              class="fm-required"
            >
              <b-form-select
                v-model="form.thermal_camera_pixel_id"
                :options="resources.thermalCameraPixelsAll"
                value-field="id"
                text-field="fullname"
                required
                @input="checkState('thermal_camera_pixel_id')"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="6">
            <b-form-group
              label="補充建議"
            >
              <b-form-textarea
                v-model="form.suggestion"
                rows="3"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="備註"
            >
              <b-form-input
                v-model="form.note"
                type="text"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="4">
            <b-form-group
              label="影片"
              description="僅支援 MP4 檔"
            >
              <b-form-file
                ref="ref-video"
                v-model="form.video"
                browse-text="瀏覽"
                placeholder="請選擇影片"
                drop-placeholder="將影片放到這裡"
                @input="checkExtension('video')"
              ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="案場圖片"
              description="僅支援 JPG、PNG、BMP 檔"
            >
              <b-form-file
                ref="ref-case_scene_photo"
                v-model="form.case_scene_photo"
                browse-text="瀏覽"
                placeholder="請選擇圖片"
                drop-placeholder="將圖片放到這裡"
                @input="checkExtension('case_scene_photo')"
              ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="正射圖"
              description="僅支援 JPG、PNG、BMP 檔"
            >
              <b-form-file
                ref="ref-orthophoto"
                v-model="form.orthophoto"
                browse-text="瀏覽"
                placeholder="請選擇圖片"
                drop-placeholder="將圖片放到這裡"
                @input="checkExtension('orthophoto')"
              ></b-form-file>
            </b-form-group>
          </b-col>
          <template v-if="modalTitle == '編輯'">
            <b-col cols="4">
              <video
                v-if="resources.detections.find(element => element.id == detectionID).video"
                :src="`https://${rootUrl}/videos/${resources.detections.find(element => element.id == detectionID).video}`"
                style="width: 100%;"
                controls
              ></video>
            </b-col>
            <b-col cols="4">
              <b-img
                v-if="resources.detections.find(element => element.id == detectionID).case_scene_photo"
                :src="`https://${rootUrl}/case-scene-photos/${resources.detections.find(element => element.id == detectionID).case_scene_photo}`"
                fluid
              ></b-img>
            </b-col>
            <b-col cols="4">
              <b-img
                v-if="resources.detections.find(element => element.id == detectionID).orthophoto"
                :src="`https://${rootUrl}/orthophotos/${resources.detections.find(element => element.id == detectionID).orthophoto}`"
                fluid
              ></b-img>
            </b-col>
          </template>
        </b-form-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { zh } from 'vuejs-datepicker/dist/locale'   // 設定語系

export default {
  components: {
    Datepicker
  },
  data () {
    return {
      zh: zh,
      rootUrl: 'platform.iruav.com.tw/uploads',
      modalTitle: null,
      detectionID: null,
      state: {
        detection_date: null,
        report_date: null,
        sunshine: null,
        temperature: null,
        wind_speed: null,
        cloud_obscuration: null,
        orthophoto_date: null,
        users_id: null,
        weather_id: null,
        clean_id: null,
        thermal_camera_pixel_id: null
      },
      form: {
        company_id: null,
        case_scene_id: null,
        detection_date: null,
        report_date: null,
        report_number: null,
        sunshine: null,
        temperature: null,
        wind_speed: null,
        cloud_obscuration: null,
        video: null,
        case_scene_photo: null,
        orthophoto: null,
        orthophoto_date: null,
        users_id: null,
        weather_id: null,
        clean_id: null,
        thermal_camera_pixel_id: null,
        note: null,
        suggestion: null
      },
      resources: {
        companys: [],
        detections: [],
        caseScenes: [],
        caseScenesAll: [],
        usersAll: [],
        usersOperators: [],
        weathersAll: [],
        cleansAll: [],
        thermalCameraPixelsAll: []
      },
      fields: [
        { key: 'index', label: '#' },
        { key: 'id', label: 'ID' },
        { key: 'show_details', label: '細項' },
        { key: 'detection_date', label: '檢測日期', sortable: true },
        { key: 'report_date', label: '報告日期', sortable: true },
        { key: 'report_number', label: '編號', sortable: true },
        { key: 'sunshine', label: '日照 W/m2', sortable: true },
        { key: 'temperature', label: '氣溫/度', sortable: true },
        { key: 'wind_speed', label: '風速 m/sec', sortable: true },
        { key: 'cloud_obscuration', label: '雲遮蔽 %', sortable: true },
        { key: 'video', label: '影片', sortable: true },
        { key: 'case_scene_photo', label: '案場圖片', sortable: true },
        { key: 'orthophoto', label: '正射圖', sortable: true },
        { key: 'orthophoto_date', label: '正射圖產生', sortable: true },
        { key: 'user_name', label: '操作者', sortable: true },
        { key: 'weather_name', label: '天氣', sortable: true },
        { key: 'clean_name', label: '清潔狀況', sortable: true },
        { key: 'thermal_camera_pixel', label: '熱像儀像素', sortable: true },
        { key: 'created_at', label: '產生時間', sortable: true },
        { key: 'updated_at', label: '更新時間', sortable: true },
        { key: 'functions', label: '功能' },
      ],
    }
  },
  methods: {
    deleteDetection(item) {
      if (confirm(`確定刪除【檢測 ID】 ${item.id}，及所包含的所有【熱缺陷】資料！`)) {
        this.axios
          .delete(`/detection/${item.id}`)
          .then(() => {
            const index = this.resources.detections.findIndex(
              element => element.id == item.id
            );
            this.resources.detections.splice(index, 1);

            this.makeToast("系統訊息", "success", "刪除成功！");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    checkState (name) {
      this.state[name] = this.form[name] ? true : false;
    },
    showModal (type, row) {
      this.$refs.modalRef.show();

      this.modalTitle = type;

      if (this.modalTitle == '新增') {
      // 編輯
      } else {
        this.detectionID = row.id;

        for (let prop in this.form) {
          if (prop != 'company_id' && prop != 'case_scene_id' && prop != 'video' && prop != 'case_scene_photo' && prop != 'orthophoto') {
            this.form[prop] = row[prop];
          }
        }
      }
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity();
      
      for (let prop in this.state) {
        this.state[prop] = this.form[prop] ? true : false;
      }

      // Explicitly check each date field
      const dateFields = ['detection_date', 'report_date', 'orthophoto_date'];
      let datesValid = true;

      dateFields.forEach(field => {
        if (!this.form[field]) {
          this.state[field] = false;
          datesValid = false;
        } else {
          this.state[field] = true;
        }
      });
      
      //return valid;
      return valid && datesValid;
    },
    resetModal () {
      /**
       * 回復預設值
       */
      for (let prop in this.form) {
        if (prop != 'company_id' && prop != 'case_scene_id') {
          this.form[prop] = null;
        }
      }

      for (let prop in this.state) {
        this.state[prop] = null
      }
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      let formData = new FormData();

      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      if (this.modalTitle == '新增') {
        formData.append('type', 'insert');

        for (let prop in this.form) {
          if (this.form[prop] && this.form[prop] != '') {
            switch (prop) {
              case 'detection_date':
              case 'report_date':
              case 'orthophoto_date':
                formData.append(prop, this.form[prop].format('Y-m-d'));
                break;
              default:
                formData.append(prop, this.form[prop]);
            }
          }
        }

        this.axios
          .post('/detection', formData)
          .then(response => {
            this.resources.detections.push(response.data.data);

            this.makeToast('系統訊息', 'success', '新增成功！');
          })
          .catch(error => {
            console.log(error);
          });
      // 編輯
      } else {
        formData.append('type', 'update');
        formData.append('id', this.detectionID);

        for (let prop in this.form) {
          if (prop === 'note') {
            formData.append(prop, this.form[prop]);
          } else if  (this.form[prop] && this.form[prop] !== '') {
            // Check if the property is one of the date fields
            if (['detection_date', 'report_date', 'orthophoto_date'].includes(prop)) {
              // Check if the value is already a string
              const formattedDate = typeof this.form[prop] === 'string' ? this.form[prop] : this.form[prop].toISOString().split('T')[0];
              formData.append(prop, formattedDate);
            } else {
              // For other fields, append the value as it is
              formData.append(prop, this.form[prop]);
            }
          }
        }

        this.axios.post('/detection', formData)
          .then(response => {
            let index = this.resources.detections.findIndex(element => element.id == response.data.data.id);
            
            this.resources.detections.splice(index, 1, response.data.data);
            this.makeToast('系統訊息', 'success', '資料已更新！');
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // Push the name to submitted names
      // this.submit.push(this.name)
      // Hide the modal manually
      this.$nextTick (() => {
        this.$refs.modalRef.hide()
      });
    },
    getCaseScene () {
      this.axios
        .get('/case-scene', { params: {
          filter: `case_scene.company_id:${this.form.company_id}`
        }})
        .then(response => {
          this.resources.caseScenes = response.data.data;
          this.form.case_scene_id = this.resources.caseScenes[0].id;

          this.getDetection();
        })
        .catch(error => {
          console.log(error);
        });
    },
    changeCompany () {
      this.getCaseScene();
    },
    getDetection () {
      this.axios
        .get('/detection', { params: {
          filter: `detection.case_scene_id:${this.form.case_scene_id}`
        }})
        .then(response => {
          this.resources.detections = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    changeDetection () {
      this.getDetection();
    },
    checkExtension (field) {
      switch (field) {
        case 'video':
          if (this.form[field] && this.form[field].type != 'video/mp4') {
              this.makeToast('系統訊息', 'danger', '僅支援 MP4 檔，請重新選擇！');

              this.$refs[`ref-${field}`].reset();
          }
          break;
        case 'case_scene_photo':
        case 'orthophoto':
          if (this.form[field] && this.form[field].type != 'image/jpeg' && this.form[field].type != 'image/png' && this.form[field].type != 'image/bmp') {
              this.makeToast('系統訊息', 'danger', '僅支援 JPG、PNG、BMP 檔，請重新選擇！');

              this.$refs[`ref-${field}`].reset();
          }
          break;
      }
    }
  },
  created () {
    this.axios
      .get("/company")
      .then((response) => {
        this.resources.companys = response.data.data;
        this.form.company_id = this.resources.companys[0].id;

        this.getCaseScene();
      })
      .catch((error) => {
        console.log(error);
      });

    this.axios
      .get('/case-scene')
      .then(response => {
        this.resources.caseScenesAll = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });

    this.axios
      .get('/user')
      .then(response => {
        this.resources.usersAll = response.data.data;
        // Filter users with identity_name = "飛手"
        this.resources.usersOperators = this.resources.usersAll.filter(user => user.identity_name === '飛手');
      })
      .catch(error => {
        console.log(error);
      });

    this.axios
      .get('/weather')
      .then(response => {
        this.resources.weathersAll = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });

    this.axios
      .get('/clean')
      .then(response => {
        this.resources.cleansAll = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });

    this.axios
      .get('/thermal-camera-pixel')
      .then(response => {
        this.resources.thermalCameraPixelsAll = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
}
</script>

<style scoped>
.table >>> thead th {
  position: sticky !important;
  top: 0;
  text-align: center;
  z-index: 2;
  background-color: white;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.3);
  border-bottom: 0px;
}
.table >>> thead th,
.table >>> tbody td {
  font-size: 0.9rem;
  vertical-align: middle !important;
  /* 防止資料折行 */
  word-break: keep-all;
  white-space:nowrap;
}

/*
 * 必填欄位加 *
 */
 .fm-required >>> legend::after {
  color: red;
  content: " *";
}
</style>
