<template>
    <div>
      <!-- PDF container -->
      <div class="pdf-container">

        <!-- Loading screen -->
        <div ref="loadingScreen" :class="{ 'loading-screen': showLoadingScreen, 'loading-screen fade-out': !showLoadingScreen }">
          <div v-if="showLoadingScreen" class="loading-icon"></div>
        </div>

        <!-- Download button -->
        <div style="position:fixed; top:20px; left:20px; z-index: 10;">
          <b-button ref="download" id="download" v-b-tooltip.hover title="下載文件">
            <b-icon 
              icon="download"
              @click="downloadPDF"
            ></b-icon>
          </b-button>
        </div>

        <vue-html2pdf v-if="resources.detection.length"
          :show-layout="true"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="false"
          :filename= "pdfName"
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-orientation="portrait"
          margin="0"
          :html2canvas="{
            useCORS: true,
            allowTaint: true,
            //proxy: 'https://platform.iruav.com.tw/', 
          }"

          ref="html2Pdf"

          style="display:flex; justify-content:center;"
        > 
        <!-- 
          :paginate-elements-by-height="1400"
          pdf-content-width="800px" 
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
        -->
          <section slot="pdf-content">
            
            <!-- Page 1 -->
            <div class="pdf-spacer-5" data-html2canvas-ignore="true"></div>
            <div class="pdf-page" ref="page1">
              <div v-if="resources.detection.length && resources.caseScene.length" class="pdf-content pdf-item">
                <div v-html="pageHeader"></div>

                <h1>熱影像空拍工程報告書</h1>
                <div style="text-align:center; padding: 0 0 7mm 0">
                  報告編號：IRUAV{{ resources.detection[0].report_date.replace(/-/g, '') }} R {{ resources.detection[0].report_number.toString().padStart(2, '0') }} &nbsp;&nbsp;&nbsp;報告發行： {{ resources.detection[0].report_date }}
                </div>
                <h5>
                  案場名稱：{{ resources.caseScene[0].name }} {{ resources.caseScene[0].device_capacity }} {{ resources.caseScene[0].capacity_unit }}
                </h5>
                <div style="text-align:center; padding: 0 0 11mm 0;">
                  <!--檢測日期：{{ resources.detection[0].detection_date.split(' ')[0] }}-->
                  檢測日期：{{ resources.detection[0].detection_date }}
                </div>
                <div>
                  <img :src="'https://' + rootUrl + '/uploads/case-scene-photos/' + addPrintSuffix(resources.detection[0].case_scene_photo)" class="site-photo" style="margin: 7mm auto; display: block;">
                </div>
                <div style="text-align:center; padding-top: 11mm;">
                  <span style="display: block;">艾瑞遙航科技有限公司</span>
                  <span style="display: block; padding-top: 3mm;">統一編號：59774228</span>
                  <span style="display: block; padding-top: 3mm;">https://www.iruav.com.tw/</span>
                </div>
              </div>
              <span class="page-number">1</span>
            </div>

            <!-- Page 2 -->
            <div class="pdf-spacer-10" data-html2canvas-ignore="true"></div>
            <div class="pdf-page" ref="page2">
              <div class="pdf-content pdf-item html2pdf__page-break">
                <div v-html="pageHeader"></div>

                <h4>案場資訊</h4>
                <table class="fixed-td-width">
                  <tbody v-if="resources.caseScene.length">
                    <tr><td>案場名稱</td><td>{{ resources.caseScene[0].name }}</td></tr>
                    <tr><td>縣市</td><td>{{ resources.caseScene[0].city }}</td></tr>
                    <tr><td>地址</td><td>{{ resources.caseScene[0].address }}</td></tr>
                    <tr><td>經度</td><td>{{ resources.caseScene[0].longitude }}</td></tr>
                    <tr><td>緯度</td><td>{{ resources.caseScene[0].latitude }}</td></tr>
                    <!--tr><td>併網日期</td><td>{{ resources.caseScene[0].merge_date.split(' ')[0] }}</td></tr-->
                    <tr><td>併網日期</td><td>{{ resources.caseScene[0].merge_date }}</td></tr>
                    <tr><td>裝置容量</td><td>{{ resources.caseScene[0].device_capacity }} {{ resources.caseScene[0].capacity_unit }}</td></tr>
                    <tr><td>模組廠牌</td><td>{{ resources.caseScene[0].module_brand }}</td></tr>
                    <tr><td>模組型號</td><td>{{ resources.caseScene[0].module_model }}</td></tr>
                    <tr><td>模組片數</td><td>{{ resources.caseScene[0].module_total }}</td></tr>
                  </tbody>
                </table>

                <h4 style="margin-top:21mm;">當日環境資訊</h4>
                <table class="fixed-td-width">
                  <tbody v-if="resources.detection.length">
                    <tr><td>天氣狀況</td><td>{{ resources.detection[0].weather_name }}</td></tr>
                    <tr><td>日照(W/m2)</td><td>{{ resources.detection[0].sunshine }}</td></tr>
                    <tr><td>氣溫(℃)</td><td>{{ resources.detection[0].temperature }}</td></tr>
                    <tr><td>雲遮蔽(%)</td><td>{{ resources.detection[0].cloud_obscuration }}</td></tr>
                    <tr><td>風速(km/h)</td><td>{{ resources.detection[0].wind_speed }}</td></tr>
                    <tr><td>熱像儀像素</td><td>{{ resources.detection[0].thermal_camera_pixel_x }} x {{ resources.detection[0].thermal_camera_pixel_y }}</td></tr>
                    <tr><td>清潔狀況</td><td>{{ resources.detection[0].clean_name }}</td></tr>
                    <tr><td>操作人</td><td>{{ resources.detection[0].user_alias }}</td></tr>
                  </tbody>
                </table>
              </div>
              <span class="page-number">2</span>
            </div>

            <!-- Page 3 -->
            <div class="pdf-spacer-10" data-html2canvas-ignore="true"></div>
            <div class="pdf-page" ref="page3">
              <div class="pdf-content pdf-item html2pdf__page-break">
                <div v-html="pageHeader"></div>

                <h4>檢測結果</h4>
                <table style="margin-bottom: 2mm;">
                  <thead>
                    <tr>
                      <th>熱缺陷種類</th>
                      <th>片數</th>
                      <th>比率%</th>
                    </tr>
                  </thead>
                  <tbody v-if="defectsReady">
                    <tr 
                      v-for="item in defectCategories"
                      :key="item.id"
                    >
                      <td>
                        <svg width="12" height="12" style="margin-right: 1mm;">
                          <circle cx="6" cy="6" r="6" :fill="item.color" />
                        </svg> 
                        {{ item.name }}
                      </td>
                      <td>{{ item.quantity }}</td>
                      <td>{{ item.percent }}</td>
                    </tr>
                  </tbody>
                </table>
                
                <p
                  v-if="resources.detection[0].note"
                  style="margin-bottom: 7mm;"
                >備註：{{ resources.detection[0].note }}</p>

                <div 
                  v-if="defectsReady"
                  style="position: relative; width: fit-content; height: fit-content; margin: 0 auto;"
                >
                  <img :src="'https://' + rootUrl + '/uploads/orthophotos/' + addPrintSuffix(resources.detection[0].orthophoto)" class="site-photo" style="display: block;">
                  <span 
                    v-for="item in resources.thermalDefects"
                    :key="item.id"
                  >
                    <div :style="{ position: 'absolute', transform: 'translate(-50%, -50%)', top: item.grid_y + '%', left: item.grid_x + '%' }">
                      <svg width="10" height="10" >
                        <circle cx="5" cy="5" r="5" :fill="item.thermal_defect_category_color" />
                      </svg>
                    </div>
                  </span>
                </div>
                <div style="text-align: center; padding-top: 5mm;">備註：熱缺陷分佈詳見網址<a href="https://platform.iruav.com.tw/" target="_blank">https://platform.iruav.com.tw/</a></div>
              </div>
              <span class="page-number">3</span>
            </div>

            <!-- Dynamic pages -->
            <div v-for="(pageContent, index) in dynamicPages" :key="index">
              <div class="pdf-spacer-10" data-html2canvas-ignore="true"></div>
              <div class="pdf-page" :ref="generatePageRef(index)">
                <div class="pdf-content pdf-item html2pdf__page-break">
                  <div v-html="pageHeader"></div>
                  <h4>熱缺陷說明</h4>
                  <div style="padding: 0 0 5mm 0;">{{ pageContent.name }} ({{ pageContent.englishName }})</div>
                  <div style="padding: 0 0 10mm 0; line-height: 2;">{{ pageContent.description }}</div>
                  <div style="text-align: center; padding: 0 0 5mm 0;">本案場可見光及熱影像圖：</div>
                  <img :src="'https://' + rootUrl + '/uploads/combine-photos/' + pageContent.firstPhoto" class="site-photo" style="margin: 0 auto; display: block; min-width: 120mm;">
                </div>
                <span class="page-number">{{ index+4 }}</span>
              </div>
            </div>

            <!-- Last page -->
            <div class="pdf-spacer-10" data-html2canvas-ignore="true"></div>
            <div class="pdf-page" ref="lastPage" style="height:296mm;">
              <div class="pdf-content pdf-item html2pdf__page-break">
                <div v-html="pageHeader"></div>

                <h4>補充建議</h4>
                <div 
                  v-if="resources.detection.length"
                >
                  <div v-if="resources.detection[0].suggestion" style="line-height:2;">
                    {{ resources.detection[0].suggestion }}
                  </div>
                  <div v-else>
                    尚無建議。
                  </div>
                </div>

                <div style="width: 30%; border-top: 1pt solid #777; margin: 15mm auto 7mm auto;"></div>
                <div style="display: block; text-align: center;">以下空白</div>

              </div>
              <div class="signature" style="display: flex;">
                <div style="width: 40%; display: flex; flex-direction: column;">
                  <img src="../assets/stamp-blue-50mm-min.png" style="align-self: flex-end; width: 40mm; height:40mm;">
                </div>
                <div style="width: 60%; display: flex; flex-direction: row; align-items: flex-end; justify-content: flex-end; gap: 5mm;">
                  <div style="display: flex; flex-direction: column; align-items: flex-end; justify-content: flex-end;">
                    <img src="../assets/signature-40mm-min.png" style="width: 35mm; height:16.6mm;">
                    <!--img :src="require('@/assets/signature-40mm-min.png')" style="width: 35mm; height:auto;"-->
                    <span style="display: block; font-size: 9pt; border-top: 2px solid #777; padding-top: 2mm;">報告簽署人：艾瑞遙航顧問</span>
                  </div>
                  <img src="../assets/stamp-red-50mm-min.png" style="align-self: flex-end; margin-top:auto; width: 40mm; height:40mm;">
                </div>
              </div>
              <span class="page-number">{{ this.dynamicPages.length+4 }}</span>
            </div>

            <div class="pdf-spacer-15" data-html2canvas-ignore="true"></div>

          </section>
        </vue-html2pdf>
      </div>
    </div>
  </template>
  
  <script>

  import VueHtml2pdf from 'vue-html2pdf';
  
  export default {
    components: {
        VueHtml2pdf
    },

    data() {
      return {
        rootUrl: 'platform.iruav.com.tw',
        showLoadingScreen: true,
        pageHeader: null,
        caseSceneId: null,
        detectionId: null,
        defectCategories: [],
        defectsReady: false,
        pdfName: '',
        resources: {
          caseScene: [],
          detection: [],
          thermalDefects: [],
          thermalDefectCategories: []
        },
      };
    },
    mounted() {
      this.getCaseScene();
    },
    computed: {
      /**
       * Get the categories with defects
       */
      dynamicPages() {
        // Filter out defectCategories with quantity !== 0
        return this.defectCategories.filter(category => category.quantity !== 0);
      }
    },
    methods: {
      /**
       * Get case scene
       */  
      getCaseScene() {
        this.caseSceneId = parseInt(localStorage.getItem('CaseSceneId'), 10);
        this.axios.get('/case-scene', { params: {
          filter : `case_scene.id:${this.caseSceneId}`
        }}) 
        .then(response => {
          this.resources.caseScene = response.data.data;

          this.getDetections();
        })
        .catch(error => {
          console.log(error);
        });
      },

      /**
       * Get detections
       */  
      getDetections() {
        this.detectionId = parseInt(localStorage.getItem('DetectionId'), 10);
        this.axios.get('/detection', { params: {
          filter : `detection.id:${this.detectionId}`
        }})
        .then(response => {
          this.resources.detection = response.data.data;
          this.pdfName = "熱影像空拍工程報告書" + this.resources.caseScene[0].name + this.resources.detection[0].report_date.replace(/-/g, '');

          this.getThermalDefect();
        })
        .catch(error => {
          console.log(error);
        });
      },

      /**
       * Get thermal defects
       */ 
      getThermalDefect() {
        this.axios.get('/thermal-defect', { params: {
          filter: `thermal_defect.detection_id:${this.detectionId}`
        }})
          .then(response => {
            this.resources.thermalDefects = response.data.data;
            
            this.getDefectCategories();
          })
          .catch(error => {
            console.log(error);
          });
      },

      /**
       * Get defect categories
       */  
      getDefectCategories() {
        this.axios.get('/thermal-defect-category')
        .then(response => {
          this.resources.thermalDefectCategories = response.data.data;
          
          this.initReport();
        })
        .catch(error => {
          console.log(error);
        });
      },

      /**
       * Init the report
       */  
      initReport() {
        
        this.pageHeader = `<div style="margin-bottom:10mm;margin-top: 10mm;"><img src="${require('@/assets/iruav-logo.png')}" style="width: 30mm; height:10.8mm;"></div>`;


        for (let i = 0, len = this.resources.thermalDefectCategories.length; i < len; i++) {
          this.defectCategories.push({
              id: this.resources.thermalDefectCategories[i].id,
              name: this.resources.thermalDefectCategories[i].name,
              englishName: this.resources.thermalDefectCategories[i].english_name,
              color: this.resources.thermalDefectCategories[i].color,
              description: this.resources.thermalDefectCategories[i].description, 
              quantity: 0,
              percent: 0,
              firstPhoto: '',
            });
        }
        for (let i = 0, len = this.resources.thermalDefects.length; i < len; i++) {
          let index = this.defectCategories.findIndex(element => element.id == this.resources.thermalDefects[i].thermal_defect_category_id);
          
          if (index === -1) {
            console.log('error: category id missing');
          } else {
            if (this.defectCategories[index].firstPhoto === '') {
              this.defectCategories[index].firstPhoto = this.resources.thermalDefects[i].combine_photo;
            }
            this.defectCategories[index].quantity++;
          }
        }
        for (let i = 0, len = this.defectCategories.length; i < len; i++) {
          this.defectCategories[i].percent = (this.defectCategories[i].quantity / this.resources.caseScene[0].module_total * 100).toFixed(2);
        }

        this.defectsReady = true;
       
        // Remove loading screen 
        if (this.showLoadingScreen) { 
          this.removeLoadingScreen();
        }
      },

      /**
       * Download PDF by printing the content
       */
      downloadPDF() {
        this.$refs.html2Pdf.generatePdf();
      },

      /**
       * Modify name of orhophoos and case scene photos to get the "print" version of the pictures
       */ 
      addPrintSuffix(filename) {
        // Split the filename into parts based on the dot (.)
        const parts = filename.split('.');

        // Insert '_print' just before the extension
        const updatedFilename = parts.slice(0, -1).join('.') + '_print.' + parts[parts.length - 1];

        return updatedFilename;
      },

      /**
       * Generate a "ref" for view attribute tag, for the category pages
       */ 
       generatePageRef(index) {
        return `category${index + 1}`;
      },

      /**
       * Remove loading screen
       */         
       removeLoadingScreen() {
        this.showLoadingScreen = false;
        setTimeout(() => {
          const loadingScreen = this.$refs.loadingScreen;
          if (loadingScreen) {
            loadingScreen.remove();
          }
        }, 500);
      },
    },
  };
  </script>
  
  <style scoped>
  .pdf-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    background-color: #100c2a;
  }
  .pdf-page {
    font-size: 12pt;
    color: black;
    width: 210mm; /* A4 paper width */
    height: 297mm; /* A4 paper height */
    margin: 0mm auto;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    overflow: hidden; 
    position: relative;
  }
  .pdf-page .page-number {
    position: absolute;
    left: 49%;
    bottom: 10mm;
    color: #888;
  }
  .pdf-page .signature {
    position: absolute;
    left: 0;
    bottom: 40mm;
    padding: 0 25mm;
    width: 100%;
  }
  .pdf-content {
    padding: 9mm 25mm;
    position: relative;
  }
  .pdf-content * {
    margin: 0;
    padding: 0;
  }
  .pdf-content h1 {
    text-align: center;
    padding: 15mm 0 15mm 0;
    font-size: 28pt;
    font-weight: bold;
  }
  .pdf-content h4 {
    text-align: center;
    padding: 0 0 10mm 0;
    font-size: 14pt;
    font-weight: bold;
  }
  .pdf-content h5 {
    text-align: center;
    padding: 0 0 7mm 0;
    font-size: 14pt;
    font-weight: bold;
  }
  .pdf-content table { 
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
  }
  .pdf-content table.fixed-td-width td:first-child, .pdf-content table.fixed-td-width th:first-child {
    width: 30%;
  }
  .pdf-content th, .pdf-content td {
    border: 1pt solid black;
    padding: .7mm 1.4mm;
    text-align: left;
  }
  .pdf-content img.site-photo {
    max-height: 100mm;
    max-width: 160mm;
    width: auto;
    height: auto;
  }
  .pdf-spacer-5 {
    padding: 5mm 0 0 0;
  }
  .pdf-spacer-10 {
    padding: 10mm 0 0 0;
  }
  .loading-screen {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #100c2a;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    z-index: 20000;
    transition: opacity .5s; 
    opacity: 1;
  }
  .loading-screen.fade-out {
    opacity: 0;
  }
  .loading-icon {
    position: fixed;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite; /* Spin animation */
    margin: auto auto; /* Center the icon */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>