<template>
  <div>
    <b-button
      class="mb-3"
      variant="outline-primary"
      @click="showModal('新增')"
    >新增</b-button>
    
    <b-table
      :items="resources.companys"
      :fields="fields"
      striped
      hover
      small
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(created_at)="data">
        {{ new Date(data.item.created_at).format('Y-m-d H:i') }}
      </template>
      <template #cell(updated_at)="data">
        {{ data.item.updated_at ? new Date(data.item.updated_at).format('Y-m-d H:i') : '' }}
      </template>
      <template #cell(functions)="data">
        <b-button
          variant="outline-primary"
          size="sm"
          class="mr-2"
          @click="showModal('編輯', data.item)"
          >編輯</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          @click="deleteCompany(data.item)"
          >刪除</b-button
        >
      </template>
    </b-table>

    <b-modal
      size="lg"
      ref="modalRef"
      :title="modalTitle"
      ok-title="儲存"
      cancel-title="取消"
      :no-close-on-backdrop="true"
      :no-close-on-esc="false"      
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-row>
          <b-col cols="4">
            <b-form-group
              label="公司名稱"
              invalid-feedback="必填"
              :state="state.name"
              class="fm-required"
            >
              <b-form-input
                v-model="form.name"
                type="text"
                autocomplete="off"
                @input="checkState('name')"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="統一編號"
              invalid-feedback="必填"
              :state="state.business_number"
              class="fm-required"
            >
              <b-form-input
                v-model="form.business_number"
                type="text"
                autocomplete="off"
                @input="checkState('business_number')"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="公司電話">
              <b-form-input
                v-model="form.company_tel"
                type="text"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="聯絡人電話">
              <b-form-input
                v-model="form.contact_tel"
                type="text"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="聯絡人信箱">
              <b-form-input
                v-model="form.contact_email"
                type="text"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="縣市"
              invalid-feedback="必填"
              :state="state.city"
              class="fm-required"
            >
              <b-form-select
                v-model="form.city"
                :options="resources.citys"
                value-field="value"
                text-field="text"
                @input="checkState('city')"
                required
              >
                <template #first>
                  <b-form-select-option :value="null">-- 請選擇 --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="地址">
              <b-form-input
                v-model="form.address"
                type="text"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      modalTitle: null,
      companyID: null,
      state: {
        name: null,
        business_number: null,
        city: null
      },
      form: {
        name: null,
        business_number: null,
        company_tel: null,
        contact_tel: null,
        contact_email: null,
        city: null,
        address: null
      },
      resources: {
        companys: [],
        citys: []
      },
      fields: [
        { key: 'index', label: '#' },
        { key: 'id', label: 'ID' },
        { key: 'name', label: '公司名稱', sortable: true },
        { key: 'business_number', label: '統一編號', sortable: true },
        { key: 'company_tel', label: '公司電話', sortable: true },
        { key: 'contact_tel', label: '聯絡人電話', sortable: true },
        { key: 'contact_email', label: '聯絡人信箱', sortable: true },
        { key: 'city', label: '縣市', sortable: true },
        { key: 'address', label: '地址', sortable: true },
        { key: 'created_at', label: '產生時間', sortable: true },
        { key: 'updated_at', label: '更新時間', sortable: true },
        { key: 'functions', label: '功能' },
      ],
    }
  },
  methods: {
    deleteCompany(item) {
      if (confirm("確定刪除！")) {
        this.axios
          .delete(`/company/${item.id}`)
          .then(() => {
            const index = this.resources.companys.findIndex(
              (element) => element.id == item.id
            );
            this.resources.companys.splice(index, 1);

            this.makeToast("系統訊息", "success", "刪除成功！");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    checkState (name) {
      this.state[name] = this.form[name] ? true : false;
    },
    showModal (type, row) {
      this.$refs.modalRef.show();

      if (type == '新增') {
        this.modalTitle = type;
      // 編輯
      } else {
        this.modalTitle = `${type} - ID ${row.id}`;
        this.companyID = row.id;

        for (let prop in this.form) {
          this.form[prop] = row[prop];
        }
      }
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity();
      
      for (let prop in this.state) {
        this.state[prop] = this.form[prop] ? true : false;
      }

      return valid;
    },
    resetModal () {
      /**
       * 回復預設值
       */
      for (let prop in this.form) {
        this.form[prop] = null
      }

      for (let prop in this.state) {
        this.state[prop] = null
      }
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      if (this.modalTitle == '新增') {
        this.axios
          .post('/company', this.form)
          .then(response => {
            this.resources.companys.push(response.data.data[0]);

            this.makeToast('系統訊息', 'success', '新增成功！');
          })
          .catch(error => {
            console.log(error);
          });
      // 編輯
      } else {
        let request = Object.assign({}, this.form);

        this.axios.patch('/company/' + this.companyID, request)
          .then(response => {
            let index = this.resources.companys.findIndex(element => element.id == response.data.data[0].id);
            
            this.resources.companys.splice(index, 1, response.data.data[0]);
            this.makeToast('系統訊息', 'success', '資料已更新！');
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // Push the name to submitted names
      // this.submit.push(this.name)
      // Hide the modal manually
      this.$nextTick (() => {
        this.$refs.modalRef.hide()
      });
    },
  },
  created () {
    this.axios
      .get('/company')
      .then(response => {
        this.resources.companys = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });

    this.axios
      .get('/city')
      .then(response => {
        this.resources.citys = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
}
</script>

<style scoped>
.table >>> thead th {
  position: sticky !important;
  top: 0;
  text-align: center;
  z-index: 2;
  background-color: white;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.3);
  border-bottom: 0px;
}
.table >>> thead th,
.table >>> tbody td {
  font-size: 0.9rem;
  vertical-align: middle !important;
  /* 防止資料折行 */
  word-break: keep-all;
  white-space:nowrap;
}

/*
 * 必填欄位加 *
 */
.fm-required >>> legend::after {
  color: red;
  content: " *";
}
</style>
